import { useState } from 'react';
import '../styles/styleCartesContainer.css';
import Carte from './carte';
import SelectSkin from './selectSkin';

export default function CartesContainer({ currentPlayer, playTurn, player, canPlay, isUpper, selectedSkin, onSkinSelect, isSpicy, nbPlayers }) {
    const [hasColors, sethasColors] = useState(true);
    const [showSelectSkin, setShowSelectSkin] = useState(false);

    if (!currentPlayer || !currentPlayer.hand) {
        console.log('currentPlayer ou hand est indéfini:', currentPlayer);
        return <p>Pas de cartes disponibles</p>;
    }

    const smallestIndex = currentPlayer.hand.findIndex(
        (card, index) => !currentPlayer.revealedCards[index] &&
            index === currentPlayer.revealedCards.findIndex((revealed, i) => !revealed && i >= index)
    );

    const largestIndex = currentPlayer.hand
        .map((card, index) => ({ card, index }))
        .filter(({ index }) => !currentPlayer.revealedCards[index])
        .sort((a, b) => b.index - a.index)[0]?.index;

    const getClassName = (index, totalCards) => {
        const midPoint = Math.floor(totalCards / 2);

        if (totalCards % 2 !== 0 && index === midPoint) {
            return 'slotCentre';
        } else if (index < midPoint) {
            return `slotGauche${midPoint - index}`;
        } else if (index > midPoint && totalCards % 2 !== 0) {
            return `slotDroite${index - midPoint}`;
        }
        else {
            return `slotDroite${index - midPoint + 1}`;
        }
    };

    const hideColors = () => {
        sethasColors(!hasColors);
    };

    const toggleSkinSelect = () => {
        setShowSelectSkin(!showSelectSkin);
    }

    return (
        <div className='CartesContainerAll'>
            <button className={`showColors ${hasColors ? '' : 'coloredBtn'}`} onClick={hideColors}>Couleurs</button>
            <button className={`selectSkin ${showSelectSkin ? 'coloredBtn' : ''}`} onClick={toggleSkinSelect}>Skins</button>
            <div className={`CartesContainer ${isUpper}`}>
                {currentPlayer.hand.length > 0 ? (
                    currentPlayer.hand.map((card, index) => (
                        <Carte
                            key={index}
                            chiffre={card.value}
                            className={getClassName(index, currentPlayer.hand.length)}
                            playTurn={playTurn}
                            player={player}
                            isSmallest={index === smallestIndex}
                            isLargest={index === largestIndex}
                            canPlay={canPlay}
                            isRevealed={currentPlayer.revealedCards[index]}
                            isTrio={card.isTrio}
                            hasColors={hasColors}
                            skin={selectedSkin}
                            isSpicy={isSpicy}
                            nbPlayers={nbPlayers}
                        />
                    ))
                ) : (
                    <p className='noCards'>Plus de cartes</p>
                )}
            </div>
            {showSelectSkin && <SelectSkin onSkinSelect={onSkinSelect} toggleSkinSelect={toggleSkinSelect}/>}
        </div>
    );
}
