import React from 'react';
import '../styles/styleCarteAdversaire.css';
import '../styles/backgroundCartes.css';
import Default from '../img/skins/Default.png'

export default function CarteAdversaire({ value, revealed, isTrio, isLargest, isSmallest, isCurrentPlayer, socket, gameId, adversaire, skin }) {

    const revealCard = (direction) => {
        socket.emit('play_turn', {
            gameId,
            action: { type: 'ask_card', direction },
            targetPlayerId: adversaire.id,
        });
    };

    const handleClick = () => {
        if (isCurrentPlayer == true) {
            if (isLargest) {
                revealCard('largest');
            } else if (isSmallest) {
                revealCard('smallest');
            }
        }
    };

    const getClassName = (num) => {
        switch (num) {
            case 1: return 'bg-1';
            case 2: return 'bg-2';
            case 3: return 'bg-3';
            case 4: return 'bg-4';
            case 5: return 'bg-5';
            case 6: return 'bg-6';
            case 7: return 'bg-7';
            case 8: return 'bg-8';
            case 9: return 'bg-9';
            case 10: return 'bg-10';
            case 11: return 'bg-11';
            case 12: return 'bg-12';
            case 13: return 'bg-13';
            default: return 'bg-default';
        }
    };

    const isUnderlined = (value) => {
        if (value === 6 || value === 9) {
            return 'underlined';
        }
    };

    return (
        <div
            className={`carte-adversaire ${revealed === 0 ? 'flipped' : 'visible'} ${isTrio ? 'isTrio' : ''} ${isLargest ? 'isLargest ' : ''} ${isSmallest ? 'isSmallest ' : ''} ${!isSmallest && !isLargest ? 'middleCard' : ''}`}
            onClick={handleClick} // Gérer le clic ici
        >
            <div className="cardAdv">
                <div className={`cardAdv-front ${getClassName(value)} ${isUnderlined(value)} ${skin?.className}`}>
                    <h1>{skin?.numberSkin[`nb${value}`] || value}</h1>
                    <img className='skinCard' src={skin?.image || Default} />
                </div>
                <div className="cardAdv-back pc">TRIPLEY</div>
                <div className={`cardAdv-back mobile ${isCurrentPlayer ? 'fontLarger' : ''}`}>{isSmallest && isCurrentPlayer ? '-' : isLargest && isCurrentPlayer ? '+' : 'TRIPLEY'}</div>
            </div>
        </div>
    );
}
