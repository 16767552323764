import '../styles/styleCarte.css';
import '../styles/styleCarte.css';
import '../styles/backgroundCartes.css';
import Default from '../img/skins/Default.png';
import { useState, useEffect } from 'react';

const getClassName = (num) => {
    switch (num) {
        case 1: return 'bg-1';
        case 2: return 'bg-2';
        case 3: return 'bg-3';
        case 4: return 'bg-4';
        case 5: return 'bg-5';
        case 6: return 'bg-6';
        case 7: return 'bg-7';
        case 8: return 'bg-8';
        case 9: return 'bg-9';
        case 10: return 'bg-10';
        case 11: return 'bg-11';
        case 12: return 'bg-12';
        case 13: return 'bg-13';
        default: return 'bg-default';
    }
};

const getClassNameIsRevealed = (isRevealed, className) => {
    return isRevealed ? 'isRevealed' : `${className}Hover`;
};

const isUnderlined = (value) => {
    return value === 6 || value === 9 ? 'underlined' : '';
};

const triosLinked = (chiffre) => {
    const links = {
        1: [6, 8],
        2: [5, 9],
        3: [4, 10],
        4: [3, 11],
        5: [2, 12],
        7: [11],
        6: [1],
        8: [1, 13],
        9: [2],
        10: [3],
        11: [4, 7],
        12: [5],
        13: [8],
    };
    return links[chiffre] || [];
};

export default function Carte({ chiffre, className, playTurn, player, isSmallest, isLargest, canPlay, isRevealed, isTrio, hasColors, skin, isSpicy, nbPlayers }) {
    const [triosLinkedVar, setTriosLinkedVar] = useState(triosLinked(chiffre));

    // Met à jour `triosLinkedVar` lorsque `chiffre` change
    useEffect(() => {
        setTriosLinkedVar(triosLinked(chiffre));
    }, [chiffre]);

    const handleClick = () => {
        if (isSmallest) {
            playTurn({ type: 'ask_card', direction: 'smallest' }, player.id);
        } else if (isLargest) {
            playTurn({ type: 'ask_card', direction: 'largest' }, player.id);
        }
    };

    return (
        <div
            className={`CarteHover ${(player.hand.length % 2) !== 0 ? className : `${className}pairAmount`} ${isSmallest == false && isLargest == false ? 'cantBePlayed' : ''} ${getClassNameIsRevealed(isRevealed, className)} ${isTrio ? 'isTrio' : ''}`}
            onClick={canPlay ? (isSmallest || isLargest ? handleClick : undefined) : null}
        >
            <div className={`Carte ${hasColors ? getClassName(chiffre) : ''} ${skin?.className}`}>
                <img src={skin?.image || Default} alt="Carte" />
                <p className={`${isUnderlined(chiffre)}`}>{skin?.numberSkin[`nb${chiffre}`] || chiffre}</p>
                <h1>{skin?.numberSkin[`nb${chiffre}`] || chiffre}</h1>
                <div className={`cardLinks ${isSpicy == false ? 'hidden' : ''}`}>
                        {triosLinkedVar.map((link, index) => (
                            <div className={`linksMiniCard ${hasColors ? getClassName(link) : ''} ${nbPlayers < 7 && link == 13 ? 'hidden' : ''}`} key={index}>{link}</div>
                        ))}
                </div>
            </div>
        </div>
    );
}
