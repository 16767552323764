import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import socket from './socket';
import CartesContainer from './components/cartesContainer';
import Carte from './components/carte';
import CartesCentralesContainer from './components/cartesCentralesContainer';
import CartesAdversaireContainer from './components/cartesAdversaireContainer';
import './styles/styleGame.css';
import './styles/styleTrios.css';
import './styles/styleSkins.css';
import './styles/backgroundCartes.css';

import Default from './img/skins/Default.png'
import Chat from './components/chat';

const triosLinked = (chiffre) => {
    const links = {
        1: [6, 8],
        2: [5, 9],
        3: [4, 10],
        4: [3, 11],
        5: [2, 12],
        7: [11],
        6: [1],
        8: [1, 13],
        9: [2],
        10: [3],
        11: [4, 7],
        12: [5],
        13: [8],
    };
    return links[chiffre] || [];
};

function Game() {
    const { gameId } = useParams();
    const location = useLocation();
    const [gameState, setGameState] = useState(location.state || null);
    const [revealedCards, setRevealedCards] = useState([]);
    const [selectedSkin, setSelectedSkin] = useState(JSON.parse(localStorage.getItem("skinSelected")));

    const [canPlay, setCanPlay] = useState(1);
    const navigate = useNavigate();

    const [winningTrio, setWinningTrio] = useState(null);
    const [showWinningTrioAnimation, setShowWinningTrioAnimation] = useState(false);

    const [canDisplayGame, setcanDisplayGame] = useState(false);

    const getClassName = (num) => {
        switch (num) {
            case 1:
                return 'bg-1';
            case 2:
                return 'bg-2';
            case 3:
                return 'bg-3';
            case 4:
                return 'bg-4';
            case 5:
                return 'bg-5';
            case 6:
                return 'bg-6';
            case 7:
                return 'bg-7';
            case 8:
                return 'bg-8';
            case 9:
                return 'bg-9';
            case 10:
                return 'bg-10';
            case 11:
                return 'bg-11';
            case 12:
                return 'bg-12';
            case 13:
                return 'bg-13';
            default:
                return 'bg-default';
        }
    };

    useEffect(() => {
        const savedPlayerName = localStorage.getItem('playerName');
        const savedGameId = localStorage.getItem('gameId');

        if (savedPlayerName && savedGameId) {
            socket.emit('reconnect_game', { gameId: savedGameId, playerName: savedPlayerName }, (response) => {
                if (response.success) {
                    setGameState(response.gameState);
                    setCanPlay(response.canPlay); // Mise à jour de l'état `canPlay`
                    setWinningTrio(response.gameState.winningTrio);
                    setShowWinningTrioAnimation(true);
                    setcanDisplayGame(true);
                    console.log('Reconnexion réussie');
                } else {
                    alert(response.message || 'Erreur lors de la reconnexion.');
                    localStorage.removeItem('playerName');
                    localStorage.removeItem('gameId');
                    navigate('/');
                }
            });
        }
    }, [navigate]);


    useEffect(() => {
        socket.on('winningTrioAnnouncement', ({ winningTrio }) => {
            setWinningTrio(winningTrio);
            setShowWinningTrioAnimation(true);
        });

        return () => {
            socket.off('winningTrioAnnouncement');
        };
    }, []);


    useEffect(() => {
        document.body.style.overflow = 'hidden';
        if (!gameState) {
            socket.emit('join_game', { gameId });
        }

        socket.on('card_revealed', ({ card, playerId }) => {
            console.log('Card revealed:', { card, playerId });
            setRevealedCards((prev) => [...prev, { value: card, playerId }]);
        });

        socket.on('game_over', ({ winner, trios }) => {
            alert(`${winner} a gagné avec les trios : ${trios.join(', ')}`);
            localStorage.removeItem('gameId');
            localStorage.removeItem('playerName');
            socket.emit('end_game_disconnect', {});
            navigate(`/`);
        });

        return () => {
            socket.off('card_revealed');
            socket.off('game_over');
            document.body.style.overflow = 'auto';
        };
    }, [gameId, gameState]);

    useEffect(() => {
        socket.on('end_turn', (updatedGame) => {
            setGameState(updatedGame);
            // Les cartes révélées restent visibles jusqu'à ce que le serveur émette 'update_game'
        });

        socket.on('update_game', (updatedGame) => {
            console.log("OMG UPDATE GAME")
            console.log(center);
            setGameState(updatedGame);
        });

        socket.on('update_can_play', (updatedCanPlay) => {
            console.log("updateCanPlay")
            setCanPlay(updatedCanPlay);
        });

        return () => {
            socket.off('end_turn');
            socket.off('update_game');
        };
    }, [socket]);

    useEffect(() => {
        socket.on('update_game_centre', (updatedGame) => {
            console.log('Mise à jour reçue par le client :', JSON.stringify(updatedGame, null, 2));
            setGameState(updatedGame); // Met à jour l'état local
        });

        return () => {
            socket.off('update_game'); // Nettoyer l'écouteur
        };
    }, [socket]);



    if (!gameState) return <p>Chargement...</p>;

    const players = gameState.players || [];
    const center = gameState.center || [];
    const currentPlayerIndex = gameState.currentPlayerIndex ?? -1;
    const currentPlayer = players.find((p) => p.id === socket.id) || {};

    const playTurn = (action, targetPlayerId, centerCardId) => {
        socket.emit('play_turn', { gameId, action, targetPlayerId, centerCardId });
    };

    let imPlaying = false;
    if (players[currentPlayerIndex] == currentPlayer) {
        imPlaying = true;
    }

    console.log(players);
    console.log(currentPlayer);
    console.log('Cé le cenctre :', JSON.stringify(gameState.center, null, 2));

    const isFirstOrLast = (index, size) => {
        if (index == 0 && size != 3) {
            return 'First';
        }
        else if (index == size - 2 && size != 3) {
            return 'Last';
        }
        else if (index == 0 && size == 3) {
            return 'DecaleGauche';
        }
        else if (index == size - 2 && size == 3) {
            return 'DecaleDroit';
        }
    }

    const isUpper3players = (size) => {
        if (size == 3) {
            return 'isUpper';
        }
    }

    const isUpper4players = (size) => {
        if (size == 4) {
            return 'isUpper4players'
        }
        else if (size == 5) {
            return 'is5players'
        }
    }

    console.log(gameState.isSpicy);

    const onSkinSelect = () => {
        const skin = JSON.parse(localStorage.getItem("skinSelected"));
        setSelectedSkin(skin);
    }




    return (
        <div className='game'>
            {canDisplayGame == true ? (


                <div className="game-container">
                    <Chat gameId={gameId} playerName={currentPlayer?.name || 'Joueur'} />

                    {/*Trio gaganant*/}
                    <div className='WinningTrioContainer'>
                        <div className='titleWinningTrio'>
                            Tripley gagnant
                        </div>

                    </div>
                    {showWinningTrioAnimation && (
                        <div className="winning-trio-animation">
                            <div className={`Carte ${'bg-' + winningTrio} ${selectedSkin?.className} cardWinningTrio`}>
                                <img src={selectedSkin?.image || Default} />
                                <p>{winningTrio}</p>
                                <h1>{winningTrio}</h1>
                            </div>
                        </div>
                    )}


                    {/* Adversaires */}
                    <div className={`adversairesContainer ${isUpper3players(players.length)} ${players.length == 7 ? 'scaleSevenPlayersAdv' : players.length == 4 ? 'scaleFourPlayersAdv' : players.length == 6 ? 'scaleSixPlayersAdv' : players.length == 5 ? 'scaleFivePlayersAdv' : ''}`}>
                        {players
                            .filter((player) => player.id !== currentPlayer.id)
                            .map((adversaire, index) => (
                                <div key={adversaire.id} className="adversaire top">
                                    <CartesAdversaireContainer
                                        adversaire={adversaire}
                                        socket={socket}
                                        gameId={gameId}
                                        isCurrentPlayer={players[currentPlayerIndex]?.id === currentPlayer.id}
                                        canPlay={canPlay}
                                        trios={adversaire.trios}
                                        position={isFirstOrLast(index, players.length)}
                                        isUpper={isUpper4players(players.length)}
                                        isPlaying={players[currentPlayerIndex].id == adversaire.id ? true : false}
                                        selectedSkin={selectedSkin}
                                        gameState={gameState}
                                    />
                                </div>
                            ))}
                    </div>
                    <p className={`tourDesc ${isUpper3players(players.length)}`}>
                        {players[currentPlayerIndex].id === currentPlayer.id
                            ? '- A VOTRE TOUR -'
                            : (
                                <span>
                                    Au tour de <br />
                                    {players[currentPlayerIndex].name}
                                </span>
                            )
                        }
                    </p>

                    {/* Cartes au centre */}
                    <div className={`cartes-centrales ${isUpper3players(players?.length)} ${players?.length == 7 ? 'scaleSevenPlayersCentre' : ''}`}>
                        <CartesCentralesContainer
                            socket={socket}
                            center={center}
                            gameId={gameId}
                            currentPlayer={currentPlayer}
                            currentPlayerIndex={currentPlayerIndex}
                            players={players}
                            canPlay={canPlay}
                            selectedSkin={selectedSkin}
                        />
                    </div>

                    {/* Main du joueur */}
                    <div className="main-joueur">
                        <CartesContainer
                            currentPlayer={currentPlayer}
                            playTurn={playTurn}
                            player={currentPlayer}
                            canPlay={canPlay}
                            isUpper={isUpper3players(players?.length)}
                            selectedSkin={selectedSkin}
                            onSkinSelect={onSkinSelect}
                            isSpicy={gameState.isSpicy}
                            nbPlayers={players?.length}
                        />
                        <div className='trios-joueur'>
                            {
                                currentPlayer.trios.map((trio, index) => (
                                    <div className='trioStackAndLinks'>
                                        <div key={index} className="trio-icon">
                                            <div className="trio-stack">
                                                <div className={`trio-bottom bigger ${getClassName(trio)}`}></div>
                                                <div className={`trio-middle bigger ${getClassName(trio)}`}></div>
                                                <div className={`trio-top bigger ${getClassName(trio)} ${selectedSkin?.className}`}>
                                                    <img src={selectedSkin?.image || Default} />
                                                    <p>{selectedSkin?.numberSkin[`nb${trio}`] || trio}</p></div>
                                            </div>
                                        </div>
                                        <div className={`linksTrios ${gameState.isSpicy == false ? 'hidden' : ''}`}>

                                            {
                                                triosLinked(trio).map((link, index) => (
                                                    <div className={`linksMiniCard ${getClassName(link)}`} key={index}>{link}</div>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>


                </div>
            ) : <div class="reconnexion-text">
                Reconnexion<span>.</span><span>.</span><span>.</span>
            </div>}
        </div>
    );
}

export default Game;
