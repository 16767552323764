import '../styles/styleSelectSkin.css';
import '../styles/styleSkins.css';

import Default from '../img/skins/Default.png'
import Skin1 from '../img/skins/Skin1.png'
import Skin2 from '../img/skins/Skin2.png'
import Skin3 from '../img/skins/Skin3.png'
import Skin4 from '../img/skins/Skin4.png'
import Skin5 from '../img/skins/Skin5.png'
import Skin6 from '../img/skins/Skin6.png'
import Skin7 from '../img/skins/Skin7.png'
import { useState } from 'react';

export default function SelectSkin({ onSkinSelect, toggleSkinSelect }) {
    const [selectedSkin, setSelectedSkin] = useState(JSON.parse(localStorage.getItem("skinSelected")));
    const numberSkins = [
        {nb1: '1', nb2: '2', nb3: '3', nb4: '4', nb5: '5', nb6: '6', nb7: '7', nb8: '8', nb9: '9', nb10: '10', nb11: '11', nb12: '12', nb13: '13'},
        {nb1: 'A', nb2: 'B', nb3: 'C', nb4: 'D', nb5: 'E', nb6: 'F', nb7: 'G', nb8: 'H', nb9: 'I', nb10: 'J', nb11: 'K', nb12: 'L', nb13: 'M'} ,
        {nb1: 'A', nb2: '2', nb3: '3', nb4: '4', nb5: '5', nb6: '6', nb7: '7', nb8: '8', nb9: '9', nb10: '10', nb11: 'J', nb12: 'D', nb13: 'K'},
    ]
    const skins = [
        { name: 'Default', image: Default, className: 'Default', displayNumber: '1', numberSkin: numberSkins[0]},
        { name: 'Impact', image: Default, className: 'Fat', displayNumber: '2', numberSkin: numberSkins[0]},
        { name: 'Letters', image: Default, className: 'DefaultLetters', displayNumber: '3', numberSkin: numberSkins[1]},
        { name: 'Dead Blossom', image: Skin1, className: 'Skin1', displayNumber: '4', numberSkin: numberSkins[0]},
        { name: 'Breach', image: Skin2, className: 'Skin2', displayNumber: '5', numberSkin: numberSkins[0]},
        { name: 'Strings', image: Skin3, className: 'Skin3', displayNumber: '6', numberSkin: numberSkins[0]},
        { name: 'Splatters', image: Skin4, className: 'Skin4', displayNumber: '7', numberSkin: numberSkins[0]},
        { name: 'Shapes', image: Skin5, className: 'Skin5', displayNumber: '8', numberSkin: numberSkins[0]},
        { name: 'Flowers', image: Skin6, className: 'Skin6', displayNumber: '9', numberSkin: numberSkins[0]},
        { name: 'Modern Mess', image: Skin7, className: 'Skin7', displayNumber: '10', numberSkin: numberSkins[0]},
    ];

    const changeSkin = (skin) => {
        setSelectedSkin(skin);
        localStorage.setItem("skinSelected", JSON.stringify(skin));
        onSkinSelect();
    }

    return (
        <div className='selectSkinContainer'>
            <button className='Close' onClick={toggleSkinSelect}>X</button>
            <h2>Choisissez un skin :</h2>
            <div className='skins'>
                {skins.map((skin, index) => (
                    <div
                        key={index}
                        className='skinOption'
                        onClick={() => changeSkin(skin)}
                    >
                        <div className={`Carte ${'bg-'+skin.displayNumber} ${skin.className} ${selectedSkin.name === skin.name ? 'selectedSkin' : ''}`}>
                            <img src={skin?.image || Skin1} />
                            <p>{skin?.numberSkin[`nb${skin.displayNumber}`] || skin.displayNumber}</p>
                            <h1>{skin?.numberSkin[`nb${skin.displayNumber}`] || skin.displayNumber}</h1>
                        </div>
                        <p className='skinName'>{selectedSkin.name === skin.name ? '- ' : ''}{skin.name}{selectedSkin?.name === skin.name ? ' -' : ''}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}
