import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import socket from './socket';
import './styles/styleHome.css';
import MenuCards from './components/menu_cards';
import SelectSkin from './components/selectSkin';
import Default from './img/skins/Default.png'

function Home() {
    const [playerName, setPlayerName] = useState('');
    const [gameId, setGameId] = useState('');
    const navigate = useNavigate();
    const [nbPlayersParam, setnbPlayersParam] = useState("3");
    const [publicGames, setPublicGames] = useState([]); // Liste des lobbys publics
    const [isPrivate, setIsPrivate] = useState(false);
    const [isSpicy, setIsSpicy] = useState(false);
    const [showSelectSkin, setShowSelectSkin] = useState(false);

    const [selectedSkin, setSelectedSkin] = useState(JSON.parse(localStorage.getItem("skinSelected")));

    if(!selectedSkin){
        setSelectedSkin({ name: 'Default', image: Default, className: 'Default', displayNumber: '1', numberSkin: {nb1: '1', nb2: '2', nb3: '3', nb4: '4', nb5: '5', nb6: '6', nb7: '7', nb8: '8', nb9: '9', nb10: '10', nb11: '11', nb12: '12', nb13: '13'}})
        localStorage.setItem("skinSelected", JSON.stringify({ name: 'Default', image: Default, className: 'Default', displayNumber: '1', numberSkin: {nb1: '1', nb2: '2', nb3: '3', nb4: '4', nb5: '5', nb6: '6', nb7: '7', nb8: '8', nb9: '9', nb10: '10', nb11: '11', nb12: '12', nb13: '13'}}));
    }
    
    useEffect(() => {
        // Charger les lobbys publics
        socket.emit('list_public_games', (response) => {
            if (response.success) {
                setPublicGames(response.games);
            } else {
                alert('Erreur lors de la récupération des parties publiques.');
            }
        });
    }, []);

    const refreshGamesList = () => {
        socket.emit('list_public_games', (response) => {
            if (response.success) {
                setPublicGames(response.games);
            } else {
                alert('Erreur lors de la récupération des parties publiques.');
            }
        });
    }

    const createGame = () => {
        if (!playerName || playerName.trim().length === 0) {
            alert('Veuillez entrer un pseudo valide.');
            return;
        }

        const newGameId = Math.random().toString(36).substr(2, 6);
        setGameId(newGameId);

        socket.emit('create_game', {
            gameId: newGameId,
            playerName: playerName.trim(), // Évite les espaces parasites
            nbPlayersParam: nbPlayersParam,
            isPrivate: isPrivate,
            isSpicy: isSpicy
        }, (response) => {
            if (response.success) {
                localStorage.setItem('playerName', playerName);
                localStorage.setItem('gameId', newGameId);
                navigate(`/lobby/${newGameId}`);
            } else {
                alert('Erreur lors de la création de la partie.');
            }
        });
    };



    const joinGame = (gameId) => {
        if (!playerName) {
            alert('Veuillez entrer votre nom.');
            return;
        }

        socket.emit('join_game', { gameId, playerName }, (response) => {
            if (response.success) {
                localStorage.setItem('playerName', playerName);
                localStorage.setItem('gameId', gameId);
                navigate(`/lobby/${gameId}`);
            } else {
                alert(response.message); // Afficher un message d'erreur reçu du serveur
            }
        });
    };


    const handleChange = (event) => {
        setnbPlayersParam(event.target.value);
    };

    const [error, setError] = useState('');

    const handleNameChange = (e) => {
        const value = e.target.value;

        if (value.length > 13) {
            setError('Le pseudo ne doit pas dépasser 13 caractères.');
        } else {
            setError('');
        }

        if (value.length <= 13) {
            setPlayerName(value);
        }
    };

    const toggleSkinSelect = () => {
        setShowSelectSkin(!showSelectSkin);
    }

    const onSkinSelect = () => {
        const skin = JSON.parse(localStorage.getItem("skinSelected"));
        setSelectedSkin(skin);
    }

    return (
        <div className='home'>
            {showSelectSkin == true ? (<SelectSkin onSkinSelect={onSkinSelect} toggleSkinSelect={toggleSkinSelect}/>) : ''}
            <div className='nameContainer'>
                <p>Pseudo :</p>
                <input
                    className='name homeInput'
                    type="text"
                    placeholder="Votre pseudo"
                    value={playerName}
                    onChange={handleNameChange}
                />
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>

            <div className='forms'>
                <div className='formCreateGame'>
                    <select id="numbers" name="numbers" value={nbPlayersParam} onChange={handleChange} className='selectNbPlayers'>
                        <option value="3">3 joueurs</option>
                        <option value="4">4 joueurs</option>
                        <option value="5">5 joueurs</option>
                        <option value="6">6 joueurs</option>
                        <option value="7">7 joueurs</option>
                    </select>
                    <label>
                        <input
                            type="checkbox"
                            checked={isPrivate}
                            onChange={(e) => setIsPrivate(e.target.checked)}
                            className="checkbox"
                        />
                        Partie privée
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={isSpicy}
                            onChange={(e) => setIsSpicy(e.target.checked)}
                        />
                        Mode Spicy
                    </label>
                    <button onClick={createGame}>Créer une partie</button>
                </div>
                <div className='formJoinGame'>
                    <input
                        type="text"
                        placeholder="ID de la partie"
                        value={gameId}
                        onChange={(e) => setGameId(e.target.value)}
                        className='homeInput'
                    />
                    <button onClick={() => joinGame(gameId)}>Rejoindre une partie</button>
                </div>
                <div className='barreDroite'></div>
                <div className='publicGamesList'>
                    <h2>Parties publiques disponibles</h2>
                    <button onClick={refreshGamesList}>Actualiser</button>
                    <div className='gamesList'>
                        {publicGames.length > 0 ? (
                            publicGames.map((game) => (
                                <div key={game.gameId} className='lobbyGamesList'>
                                    Partie ID : {game.gameId} - {game.players}/{game.nbPlayers} joueurs
                                    <button onClick={() => joinGame(game.gameId)}>Rejoindre</button>
                                </div>
                            ))
                        ) : (
                            <p>Aucune partie trouvée</p>
                        )}
                    </div>
                </div>
            </div>
            <MenuCards toggleSkinSelect={toggleSkinSelect} selectedSkin={selectedSkin}/>
        </div>
    );
}

export default Home;
