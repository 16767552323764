import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import socket from './socket';
import './styles/styleLobby.css';

function Lobby() {
    const { gameId } = useParams();
    const navigate = useNavigate();
    const [players, setPlayers] = useState([]);
    const [gameStarted, setGameStarted] = useState(false);
    const [nbPlayersParam, setNbPlayersParam] = useState();

    useEffect(() => {
        // Rejoindre le lobby
        socket.emit('join_game', { gameId, playerName: localStorage.getItem('playerName') });

        // Écouter les mises à jour des joueurs dans le lobby
        const handleUpdateLobby = ({ players, nbPlayers }) => {
            console.log("Update Lobby :", players, nbPlayers);
            setPlayers(players);
            setNbPlayersParam(nbPlayers);
        };

        socket.on('update_lobby', handleUpdateLobby);

        // Écouter le démarrage de la partie
        socket.on('game_started', (gameData) => {
            setGameStarted(true);
            console.log('Game started data received:', gameData);
            navigate(`/game/${gameId}`, { state: gameData });
        });

        return () => {
            socket.off('update_lobby', handleUpdateLobby);
            socket.off('game_started');
        };
    }, [gameId, navigate]);



    return (
        <div className='lobby'>
            <h1>ID de la partie : {gameId}</h1>
            {gameStarted ? (
                <p>La partie commence...</p>
            ) : (
                <p>{players.length == 0 ? 1 : players.length}/{nbPlayersParam} Joueurs connectés</p>
            )}
            <h2>Joueurs en attente :</h2>
            <div className='listPlayer'>
                {players.map((player) => (
                    <div key={player.id}>{player.name}</div>
                ))}
            </div>

        </div>
    );
}

export default Lobby;
