import '../styles/styleMenuCards.css'
import '../styles/backgroundCartes.css'
import { useState } from 'react';

export default function MenuCards ({ toggleSkinSelect, selectedSkin }) {
    const [menuCardSkinsSelected, setMenuCardSkinsSelected] = useState(false);


    return (
        <div className="menuCardsContainer">
            <div className={`menuCard menuCardSkins left1MenuCard`} onClick={toggleSkinSelect}>
                <h1>Skins</h1>
            </div>
            <div className="menuCard menuCardCompte bg-1 centerMenuCard" >
                <h1>Compte</h1>
            </div>
            <div className="menuCard menuCardAbout bg-2 right1MenuCard" >
                <h1>A propos</h1>
            </div>
        </div>
    );
}