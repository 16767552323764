import React from 'react';
import '../styles/styleTrios.css';
import '../styles/styleCartesAdversaireContainer.css';
import CarteAdversaire from './carteAdversaire';
import Default from '../img/skins/Default.png'

const triosLinked = (chiffre) => {
    const links = {
        1: [6, 8],
        2: [5, 9],
        3: [4, 10],
        4: [3, 11],
        5: [2, 12],
        7: [11],
        6: [1],
        8: [1, 13],
        9: [2],
        10: [3],
        11: [4, 7],
        12: [5],
        13: [8],
    };
    return links[chiffre] || [];
};

export default function CartesAdversaireContainer({ adversaire, socket, gameId, isCurrentPlayer, canPlay, trios, position, isUpper, isPlaying, selectedSkin, gameState }) {
    const revealCard = (direction) => {
        socket.emit('play_turn', {
            gameId,
            action: { type: 'ask_card', direction },
            targetPlayerId: adversaire.id,
        });
    };

    const areBtnVisible = (isCurrentPlayer) => {
        if (isCurrentPlayer == 0) {
            return 'hideBtn';
        }
    };

    const getClassName = (num) => {
        switch (num) {
            case 1:
                return 'bg-1';
            case 2:
                return 'bg-2';
            case 3:
                return 'bg-3';
            case 4:
                return 'bg-4';
            case 5:
                return 'bg-5';
            case 6:
                return 'bg-6';
            case 7:
                return 'bg-7';
            case 8:
                return 'bg-8';
            case 9:
                return 'bg-9';
            case 10:
                return 'bg-10';
            case 11:
                return 'bg-11';
            case 12:
                return 'bg-12';
            case 13:
                return 'bg-13';
            default:
                return 'bg-default';
        }
    };

    const nbCards = (nbOfCards) => {
        if(nbOfCards == 0){
            return "nocards";
        }
    }

        // Trouver la plus petite carte non révélée
        const smallestIndex = adversaire.hand.findIndex(
            (card, index) => !adversaire.revealedCards[index] &&
                index === adversaire.revealedCards.findIndex((revealed, i) => !revealed && i >= index)
        );
    
        // Trouver la plus grande carte non révélée
        const largestIndex = adversaire.hand
            .map((card, index) => ({ card, index }))
            .filter(({ index }) => !adversaire.revealedCards[index])
            .sort((a, b) => b.index - a.index)[0]?.index;

    return (
        <div className={`cartes-adversaire-container ${'position' + position} ${isUpper}`}>
            <div className="adversaire-header">
                <div className='nameAndTriosContainer'>
                {isPlaying
                        ? (<p className='name'>- {adversaire.name} -</p>)
                        : (
                            <p className='name'>{adversaire.name}</p>
                        )
                    }
                </div>
                {adversaire.hand.length > 0 ? (
                <div className='buttonsContainer'>
                    <button
                        onClick={canPlay == 1 ? () => revealCard('smallest') : null}
                        disabled={!isCurrentPlayer}
                        className={`smallest ${areBtnVisible(isCurrentPlayer)}`}
                    >
                        -
                    </button>
                    <button
                        onClick={canPlay == 1 ? () => revealCard('largest') : null}
                        disabled={!isCurrentPlayer}
                        className={`largest ${areBtnVisible(isCurrentPlayer)}`}
                    >
                        +
                    </button>
                </div>
                ): (<div className='buttonsContainer'></div>)}
            </div>

            <div className='cartes-adversaire-et-trios'>

                <div className={`cartes-adversaire`}>
                    {adversaire.hand.map((card, index) => (
                        <CarteAdversaire
                            key={index}
                            value={card.value}
                            revealed={adversaire.revealedCards[index]}
                            isTrio={card.isTrio}
                            isSmallest={index === smallestIndex} // Détermine si c'est la plus petite non révélée
                            isLargest={index === largestIndex} // Détermine si c'est la plus grande non révélée
                            isCurrentPlayer={isCurrentPlayer}
                            socket={socket}
                            gameId={gameId}
                            adversaire={adversaire}
                            skin={selectedSkin}
                        />
                    ))}
                </div>
                <div className='nbCartes mobile'>
                    <p>{adversaire.hand.length} Cartes</p>
                </div>
                <div className={`trios-adversaire-container ${nbCards(adversaire.hand.length)}`}>
                    <div className="trios-container">
                        {trios.map((trio, index) => (
                            <div className='trioStackAndLinks'>
                            <div key={index} className="trio-icon">
                                <div className="trio-stack">
                                    <div className={`trio-bottom  ${getClassName(trio)}`}></div>
                                    <div className={`trio-middle  ${getClassName(trio)}`}></div>
                                    <div className={`trio-top  ${getClassName(trio)} ${selectedSkin?.className}`}>
                                        <img src={selectedSkin?.image || Default} />
                                        <p>{selectedSkin?.numberSkin[`nb${trio}`] || trio}</p></div>
                                </div>
                            </div>
                            <div className={`linksTrios smaller ${gameState.isSpicy == false ? 'hidden' : ''}`}>
                                
                                {triosLinked(trio).map((link, index) => (
                                    <div className={`linksMiniCard ${getClassName(link)}`} key={index}>{link}</div>
                                ))}
                            </div>
                        </div>
                        ))}

                    </div>
                </div>

            </div>


        </div>
    );
}
