import React, { useState, useEffect } from 'react';
import socket from '../socket'; 
import '../styles/styleChat.css'
import sendIcon from '../img/icones/send.png'

export default function Chat({ gameId, playerName }) {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [chatToggled, setChatToggled] = useState(false);
    const [newMessage, setNewMessage] = useState(false);

    useEffect(() => {
        socket.on('receive_message', (chatMessage) => {
            setMessages((prevMessages) => [...prevMessages, chatMessage]);
            if(chatToggled == false){
                setNewMessage(true);
            }
        });

        return () => {
            socket.off('receive_message');
        };
    }, []);

    useEffect(() => {
        const chatMessages = document.querySelector('.chat-messages');
        if (chatMessages) {
            chatMessages.scrollTop = chatMessages.scrollHeight;
        }
    }, [messages]);
    

    const sendMessage = () => {
        if (inputMessage.trim()) {
            socket.emit('send_message', { gameId, playerName, message: inputMessage });
            setInputMessage(''); // Clear input
        }
    };

    const toggleChat = () => {
        setChatToggled(!chatToggled);
        setNewMessage(false);
    };

    return (
        <div className={`chat-container ${chatToggled == true ? 'chatOppened' : 'chatClosed'}`}>
            {newMessage == true && chatToggled == false ? (<div className='notificationDot'></div>) : ''}
            <button className='toggleChat' onClick={toggleChat}>{chatToggled == true ? '<' : '>'}</button>
            <div className="chat-messages">
                {messages.map((msg, index) => (
                    <div key={index} className="chat-message">
                        <strong>{msg.playerName}:</strong> {msg.message}
                    </div>
                ))}
            </div>
            <div className="chat-input">
                <input
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                />
                <button onClick={sendMessage}><img src={sendIcon} className='sendIcon'/></button>
            </div>
        </div>
    );
}
